/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

class Footer {
	constructor() {
		this.setupChat = this.setupChat.bind(this);
	}

	create(model) {
		this.model.set('loaded', true);
		return setTimeout(this.setupChat, 100);
	}

	setupChat() {
		if (typeof window.Livechat !== 'undefined') {
			const email = this.model.root.get('_page.user.local.email') || '';
			const name = this.model.root.get('_page.user.local.fullname') || '';

			return new Livechat({ email, name });

		} else {
			return setTimeout(this.setupChat, 100);
		}
	}
}

app.component('footer', Footer);
