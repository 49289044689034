/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const linkify = require('linkify-it')();
const app = require('../index');

class Try {

	constructor() {
		this.delEmptied = this.delEmptied.bind(this);
		this.changed = this.changed.bind(this);
		this.getpreview = this.getpreview.bind(this);
	}

	create() {
		this.model.set('form_ready', true);
		this.model.ref('card', this.model.root.at('_page.card'));
		this.model.ref('screenshot', this.model.root.at('_page.screenshot'));
		//@model.on 'change', 'text', @changed
		return this.input.focus();
	}

	destroy() {
		return this.model.del('card');
	}

	setEmptied(url) {
		this.model.set('emptied', 'Ok, emptied ' + url);
		return setTimeout(this.delEmptied, 5000);
	}

	preferOembedInIframe() {
		return false;
	}

	preferOembedStraight() {
		const item = this.model.get('card');
		return (__guard__(item != null ? item.oembed : undefined, x => x.type) === 'rich') && (item != null ? item.url.match(/^https?:\/\/(?:www\.)?(?:airtable\.com|mathembed\.com|soundcloud\.com|facebook\.com|twitter\.com)/i) : undefined);
	}

	delEmptied(url) {
		return this.model.del('emptied');
	}

	emptyCache() {
		const text = this.model.get('text');

		if (typeof text === 'undefined') { return; }

		const matches = linkify.match(text) || [];
		const urls = (Array.from(matches).map((m) => (m != null ? m.url : undefined)));

		if ((urls != null ? urls.length : undefined) === 1) {
			this.setEmptied(urls[0]);
			return this.model.root.push('misc.hooks.emptycache', { url: urls[0] });
		}
	}
			//setTimeout @getpreview, 1000

	changed() {
		clearTimeout(this.timer);
		return this.timer = setTimeout(this.getpreview, 1000);
	}

	getpreview(e, method) {
		if (method == null) { method = 'getpreview'; }
		e.preventDefault();
		this.model.del('emptied');
		const text = this.model.get('text');
		console.log(text);

		if (typeof text === 'undefined') { return; }

		const matches = linkify.match(text) || [];
		const urls = (Array.from(matches).map((m) => (m != null ? m.url : undefined)));

		console.log({matches, urls});

		if ((urls != null ? urls.length : undefined) === 1) {
			this.model.set('card', 'fetch');
			return this.model.root.push('misc.hooks.list', { method, url: urls[0] });
		}
	}
}


app.component('try', Try);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}