/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let Helpers;
const app = require('../index');

app.proto.lower = el => el.value = el.value.toLowerCase();

app.component(Helpers = (function() {
	Helpers = class Helpers {
		static initClass() {
			this.prototype.name = 'helpers';
		}

		create(model, dom) {
			window.iFrameResize = require('iframe-resizer/js/iframeResizer.min.js');
			window.iFrameResize({ checkOrigin: false });
			//ScrollReveal = require '../../scrollreveal.min.js'
			//sr = ScrollReveal({ viewFactor: 0.6, scale: 1, distance: '100px' })
			//sr.reveal('section > div.appear');

			return window.m = model.root;
		}
	};
	Helpers.initClass();
	return Helpers;
})()
);
	
