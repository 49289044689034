/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');

app.get('/try', function(page, model, params, next) {
	const userId = model.get('_session.userId');
	const hooks = model.at('misc.hooks');
	const cards = model.at('misc.cards');
	const screenshots = model.at('misc.screenshots');
	return model.subscribe(hooks, cards, screenshots, function(err) {
		model.ref('_page.card', cards.at(userId));
		model.ref('_page.screenshot', screenshots.at(userId));
		model.del('_page.card');
		model.del('_page.screenshot');
		return page.render('try');
	});
});


