/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');

app.get('/docs', function(page, model, params, next) {
  model.set('_page.card7', {
        "url": "https://twitter.com/CatVlDEOS/status/1185826827333525504",
        "site": "Twitter",
        "favicon": {
        "url": "https://abs.twimg.com/icons/apple-touch-icon-192x192.png",
        "safe": "https%3A%2F%2Fabs.twimg.com%2Ficons%2Fapple-touch-icon-192x192.png"
        },
        "title": "Cat Pics & Videos 🐈 on Twitter",
        "description": "Don't touch my toy hooman 😠 https://t.co/2Le3GSjORL",
        "images": [
          {
            "url": "https://pbs.twimg.com/profile_images/1049047193083138049/3gLZVrmP.jpg",
            "safe": "https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1049047193083138049%2F3gLZVrmP.jpg",
            "width": 400,
            "height": 400
          }
        ],
        "videos": [],
        "audios": [],
        "type": "rich",
        "oembed": {
        "url": "https://twitter.com/CatVlDEOS/status/1185826827333525504",
        "author_name": "Cat Pics & Videos 🐈",
        "author_url": "https://twitter.com/CatVlDEOS",
        "html": "<blockquote class=\"twitter-tweet\"><p lang=\"en\" dir=\"ltr\">Don&#39;t touch my toy hooman 😠 <a href=\"https://t.co/2Le3GSjORL\">pic.twitter.com/2Le3GSjORL</a></p>&mdash; Cat Pics &amp; Videos 🐈 (@CatVlDEOS) <a href=\"https://twitter.com/CatVlDEOS/status/1185826827333525504?ref_src=twsrc%5Etfw\">October 20, 2019</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n",
        "width": 550,
        "height": null,
        "type": "rich",
        "cache_age": "3153600000",
        "provider_name": "Twitter",
        "provider_url": "https://twitter.com",
        "version": "1.0"
        },
        "duration": 395,
        "html": "<div class=\"card\">\n    <a target=\"_blank\" href=\"https://twitter.com/CatVlDEOS/status/1185826827333525504\">\n      \n    <img onerror=\"this.style.display='none'\" class=\"card-image\" src=\"https://pbs.twimg.com/profile_images/1049047193083138049/3gLZVrmP.jpg\">\n  \n      \n    <div class=\"card-text\">\n      <h3>Cat Pics & Videos 🐈 on Twitter</h3>\n      <p>Don't touch my toy hooman 😠 https://t.co/2Le3GSjORL</p>\n      \n        <p class=\"fav\">\n          <img onerror=\"this.style.display='none'\" class=\"favicon\" src=\"https://abs.twimg.com/icons/apple-touch-icon-192x192.png\">\n          Twitter\n        </p>\n    </div>\n  \n    </a>\n    </div>"
      });

  model.set('_page.card2', {
          "article": "<div><p>\n    <strong>Pour évaluer une vidéo, vous devez la louer.</strong>\n  </p></div>",
          "description": "A few pointers from my experience on things one should either do or avoid while in Finland. So some Finnish do's and don'ts! :) *On a side note, I know I hav...",
          "favicon": {
            "safe": "https%3A%2F%2Fs.ytimg.com%2Fyts%2Fimg%2Ffavicon-vflz7uhzw.ico",
            "url": "https://s.ytimg.com/yts/img/favicon-vflz7uhzw.ico"
          },
          "html": "<div class=\"card\">\n    <iframe style=\"width: 720px; height: 405px\" src=\"https://www.youtube.com/embed/9N0HB8vMJrI\"></iframe>\n    \n    <div class=\"card-text\">\n      <h3>Do's and Don'ts in Finland | KatChats</h3>\n      <p>A few pointers from my experience on things one should either do or avoid while in Finland. So some Finnish do's and don'ts! :) *On a side note, I know I hav...</p>\n      \n        <p class=\"fav\">\n          <img onerror=\"this.style.display='none'\" class=\"favicon\" src=\"https://s.ytimg.com/yts/img/favicon-vflz7uhzw.ico\">\n          YouTube\n        </p>\n    </div>\n  \n  </div>",
          "images": [
            {
              "safe": "https%3A%2F%2Fi.ytimg.com%2Fvi%2F9N0HB8vMJrI%2Fmaxresdefault.jpg",
              "url": "https://i.ytimg.com/vi/9N0HB8vMJrI/maxresdefault.jpg"
            }
          ],
          "oembed": {
            "author_name": "KatChats",
            "author_url": "https://www.youtube.com/user/adamLsidney",
            "height": 270,
            "html": "<iframe width=\"480\" height=\"270\" src=\"https://www.youtube.com/embed/9N0HB8vMJrI?feature=oembed\" frameborder=\"0\" allowfullscreen></iframe>",
            "provider_name": "YouTube",
            "provider_url": "https://www.youtube.com/",
            "thumbnail_height": 360,
            "thumbnail_url": "https://i.ytimg.com/vi/9N0HB8vMJrI/hqdefault.jpg",
            "thumbnail_width": 480,
            "title": "Do's and Don'ts in Finland | KatChats",
            "type": "video",
            "version": "1.0",
            "width": 480
          },
          "site": "YouTube",
          "title": "Do's and Don'ts in Finland | KatChats",
          "type": "video",
          "url": "https://www.youtube.com/watch?v=9N0HB8vMJrI",
          "videos": [
            {
              "height": "720",
              "secureUrl": "https://www.youtube.com/embed/9N0HB8vMJrI",
              "type": "text/html",
              "url": "https://www.youtube.com/embed/9N0HB8vMJrI",
              "width": "1280"
            },
            {
              "height": "720",
              "secureUrl": "https://www.youtube.com/v/9N0HB8vMJrI?version=3&autohide=1",
              "type": "application/x-shockwave-flash",
              "url": "http://www.youtube.com/v/9N0HB8vMJrI?version=3&autohide=1",
              "width": "1280"
            },
            {
              "height": "720",
              "type": "text/html",
              "url": "https://www.youtube.com/embed/9N0HB8vMJrI",
              "width": "1280"
            }
          ]
        });

  model.set('_page.card1', {
          "article": "<div>(article omitted in the example)</div>",
          "description": "AirlineRatings.com declares 2016 one of the safest years in aviation history as it names what it says are the world's safest airlines.",
          "favicon": {
            "safe": "http%3A%2F%2Fedition.cnn.com%2Ffavicon.ie9.ico",
            "url": "http://edition.cnn.com/favicon.ie9.ico"
          },
          "images": [
            {
              "safe": "http%3A%2F%2Fi2.cdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F160412104516-airplane-generic-ii-super-tease.jpg",
              "url": "http://i2.cdn.cnn.com/cnnnext/dam/assets/160412104516-airplane-generic-ii-super-tease.jpg"
            }
          ],
          "site": "CNN",
          "title": "The world's safest airlines for 2017?",
          "type": "rich",
          "url": "http://edition.cnn.com/2017/01/05/aviation/safest-airlines-for-2017/index.html",
          "videos": []
        });

  return page.render('docs');
});


