module.exports = {
	usernamePattern: "^[a-zA-Z][A-Za-z0-9_.]{2,22}$",
	domain: process.env.NODE_ENV === 'production'? "https://embed.rocks": "http://dev.embed.rocks",
	price: 0.0005,
	trial: 1000,
	"embed-api": "https://api.embed.rocks/api/",
	"embed-api-screenshot": "https://api.embed.rocks/screenshot/",
	"embed-api-empty-cache": "https://api.embed.rocks/empty/",
	nonprod: process.env.NODE_ENV === 'production'? 0: 1,
	stripe: process.env.STRIPE_PUBLIC
}

