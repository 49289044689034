/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');

// require the highlight.js library without languages
const hljs = require("highlight.js");

// separately require languages
hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript.js'));
hljs.registerLanguage('ruby', require('highlight.js/lib/languages/ruby.js'));
hljs.registerLanguage('ruby', require('highlight.js/lib/languages/ruby.js'));
hljs.registerLanguage('php', require('highlight.js/lib/languages/php.js'));
hljs.registerLanguage('python', require('highlight.js/lib/languages/python.js'));
hljs.registerLanguage('go', require('highlight.js/lib/languages/go.js'));
hljs.registerLanguage('bash', require('highlight.js/lib/languages/bash.js'));



class Docs {
	static initClass() {
		
		this.prototype.codes = {
			curl: {
				type: 'bash',
				code: '$ curl -H "x-api-key:mykey" https://api.embed.rocks/api?url=https%3A%2F%2Fwww.bbc.com%2Fsport%2Frugby-union%2F44325008'
			},
			wget: {
				type: 'bash',
				code: '$ wget --header="x-api-key:mykey" https://api.embed.rocks/api?url=https%3A%2F%2Fwww.bbc.com%2Fsport%2Frugby-union%2F44325008'
			},
			node: {
				type: 'javascript',
				code: `\
const https = require('https');

const url = encodeURIComponent('https://www.bbc.com/sport/rugby-union/44325008');
const options = {
	hostname: 'api.embed.rocks',
	path: '/api/?url=' + url,
	headers: {
		'x-api-key': 'mykey'
	}
};

https.request(options, (response) => {
  let body = '';
  response.on('data', chunk => body += chunk).on('end', () => console.log(body));
}).end();\
`
			},
	
			ruby: {
				type: 'ruby',
				code: `\
require 'net/https'    

uri = URI('https://api.embed.rocks/api')
uri.query = URI.encode_www_form({ url: 'https://www.bbc.com/sport/rugby-union/44325008' })

Net::HTTP.start(uri.host, uri.port,   
  :use_ssl => uri.scheme == 'https') do |http|
  request = Net::HTTP::Get.new uri
  request['x-api-key'] = 'mykey'

  response = http.request request # Net::HTTPResponse object 
  puts response.body
end\
`
			},
	
			php: {
				type: 'php',
				code: `\
$url = urlencode('https://www.bbc.com/sport/rugby-union/44325008');

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, 'https://api.embed.rocks/api/?url=' . $url);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, array(
    'x-api-key: mykey'
));

$response = curl_exec($ch);
curl_close($ch);

var_dump($response);\
`
			},
	
			python: {
				type: 'python',
				code: `\
import urllib2 
from urllib import quote_plus

url = quote_plus('https://www.bbc.com/sport/rugby-union/44325008')
req = urllib2.Request('https://api.embed.rocks/api/?url=' + url)
req.add_header('x-api-key', 'mykey')
response = urllib2.urlopen(req)

print response.read()\
`
			},
	
			go: {
				type: 'go',
				code: `\
package main

import (
	"net/http"
	"fmt"
	"io/ioutil"
	"net/url"
)

func main() {
	url := url.QueryEscape("https://www.bbc.com/sport/rugby-union/44325008")

	client := &http.Client{}
	req, _ := http.NewRequest("GET", "https://api.embed.rocks/api?url=" + url, nil)
	req.Header.Set("x-api-key", "mykey")
	res, _ := client.Do(req)

	defer res.Body.Close()
	body, _ := ioutil.ReadAll(res.Body)

	fmt.Println("response Body: ", string(body))
}\
`
			}
		};
	}


	init() {
		return this.code('curl');
	}

	code(lang) {
		const code = hljs.highlight(this.codes[lang].type, this.codes[lang].code).value;
		this.model.set('selected', lang);
		this.model.set('lang', `language-${this.codes[lang].type}`);
		return this.model.set('code', code);
	}
}
Docs.initClass();


app.component('docs', Docs);


