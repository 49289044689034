/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
require('aamu-highway/lib/browser/index.js')({});
const plugin = require('aamu-webpack/lib/plugin.js');
let derby = require('aamu-derby');
plugin(derby.App);
let app = (module.exports = derby.createApp('some', __filename));

app.serverUse(module, 'k-stylus');
app.loadViews(__dirname + '/../../views/app');
app.loadStyles(__dirname + '/../../styles/app');

app.component(require('sc-connection-alert'));
app.component(require('k-before-unload'));
app.component(require('k-dialog'));
app.component(require('k-avatar'));
app.component(require('aamu-toast'));
app.use(require('aamu-flash'), { useToast: true });
app.component(require('k-popup'));

// error catching, otherwise the app may crash if an uncaught error is thrown
app.on('model', model => model.on('error', err => console.error(err)));

require('./routes/users');
require('./routes/home');
require('./routes/docs');
require('./routes/pricing');
require('./routes/static');
require('./routes/profile');
require('./routes/try');
require('./routes/news');
require('./routes/settings');
require('./routes/dashboard');
require('./components/dashboard');
require('./components/pricing');
require('./components/helpers');
require('./components/try');
require('./components/card');
require('./components/home');
require('./components/docs');
require('./components/footer');
