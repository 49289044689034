/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');

app.get('/dashboard', function(page, model, params, next) {
	const userId = model.get('_page.user.id');
	if (!userId) {
		return page.render('error:404');
	} else {
		const pendingQ = model.query('invoiceitems', { user: userId, status: 'unpaid' });
		const paidQ = model.query('invoices', { user: userId, $orderby: { 'data.object.date': -1 } });
		return model.execute('usagebyuser', { v0: userId }, function(err, data) {		
			model.set('_page.usagebyuser', data);
			return model.subscribe(pendingQ, paidQ, function(err) {
				model.ref('_page.pending', pendingQ);
				model.ref('_page.paid', paidQ);
				return page.render('dashboard');
			});
		});
	}
});


