/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');
const d3 = require('d3');

if (!Date.prototype.toISOString) {
	(function() {

		const pad = function(number) {
			if (number < 10) {
				return '0' + number;
			}
			return number;
		};

		Date.prototype.toISOString = function() {
			return this.getUTCFullYear() + '-' + pad(this.getUTCMonth() + 1) + '-' + pad(this.getUTCDate()) + 'T' + pad(this.getUTCHours()) + ':' + pad(this.getUTCMinutes()) + ':' + pad(this.getUTCSeconds()) + '.' + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) + 'Z';
		};

	})();
}


class Dashboard {

	constructor() {
		this.payInvoices = this.payInvoices.bind(this);
		this.addSomeUsage = this.addSomeUsage.bind(this);
		this.maxExpenseEdited = this.maxExpenseEdited.bind(this);
	}

	init() {
		this.constructMonthlyUsage();
		this.model.ref('pending', this.model.root.at('_page.pending'));
		this.model.ref('paid', this.model.root.at('_page.paid'));
		this.model.start('total_pending', 'pending', this.calculateTotalPending);
		return this.model.start('total_paid', 'paid', this.calculateTotalPaid);
	}

	create() {
		console.log(this.model.root.get('_page.usagebyuser'));
		this.model.set('max_expense', this.model.root.get('_page.user_pvt.max_expense'));
		this.model.on('change', 'max_expense', this.maxExpenseEdited);
		return this.usageGraph();
	}

	cancelApiChange(e) {
		return this.model.del('change_api');
	}

	changeAPIExecute(e) {}

	changeAPI(e) {
		e.preventDefault(); 
		return this.model.set('change_api', true);
	}

	amount(amount) {
		return (amount / 100).toFixed(2);
	}

	totalUrls(invoice) {
		return __guard__(__guard__(__guard__(invoice != null ? invoice.data : undefined, x2 => x2.object), x1 => x1.lines), x => x.data.map(el => (__guard__(el != null ? el.metadata : undefined, x3 => x3.count) && parseInt(__guard__(el != null ? el.metadata : undefined, x4 => x4.count), 10)) || 0).reduce(( (acc, cur) => acc + cur), 0));
	}

	calculateTotalPending(pending) {
		if (pending == null) { pending = []; }
		let total = 0;

		for (var p of Array.from(pending)) {
			if (p != null) {
				total += p.amount;
			}
		}

		return (total / 100).toFixed(2);
	}

	calculateTotalPaid(paid) {
		if (paid == null) { paid = []; }
		let total = 0;

		for (var p of Array.from(paid)) {
			if (__guard__(__guard__(p != null ? p.data : undefined, x1 => x1.object), x => x.total)) {
				total += p.data.object.total;
			}
		}

		return (total / 100).toFixed(2);
	}

	price(n) {
		return (n/100).toFixed(2);
	}

	constructMonthlyUsage() {
		let ts;
		const daily = this.model.root.get('_page.usagebyuser');
		const monthly = {};
		const monthlyArr = [];

		for (var d of Array.from(daily)) {
			ts = new Date(d.ts);
			var tsMonthly = new Date(ts.getUTCFullYear(), ts.getUTCMonth());
			var tsMonthlyTs = tsMonthly.valueOf();

			if (!monthly[tsMonthlyTs]) {
				monthly[tsMonthlyTs] = 0;
			}
			
			monthly[tsMonthlyTs] += d.usage;
		}

		const keys = Object.keys(monthly);

		for (var key of Array.from(keys)) {
			monthlyArr.push({ts: parseInt(key, 10), usage: monthly[key]});
		}

		return this.model.set('monthlyUsage', monthlyArr);
	}


	nicedate(ts) {
		const d = new Date(ts);
		return d.toLocaleString('en', { month: "long"  }) + ' ' + d.toLocaleString('en', { year: "numeric" });
	}

	date(n) {
		const pad = function(number) {
			if (number < 10) {
				return '0' + number;
			}
			return number;
		};

		const d = new Date(n * 1000);
		return d.getUTCFullYear() + '-' + pad(d.getUTCMonth() + 1) + '-' + pad(d.getUTCDate());
	}


	payInvoices() {
		return util.toServer(this.model.root.at('_page.user'), 'pay-invoices', { });
	}

	addSomeUsage() {
		return util.toServer(this.model.root.at('_page.user'), 'add-some-usage', { });
	}

	maxExpenseEdited() {
		return this.model.set('maxExpenseEdited', true);
	}

	setMaxExpense() {
		const expense = this.model.get('max_expense');
		if (isNaN(expense)) {
			return this.model.toast('error', 'Not a number');
		} else {
			util.toServer(this.model.root.at('_page.user'), 'set-max-expense', { expense });
			this.model.del('maxExpenseEdited');
			return this.model.toast('success', 'OK, maximum expense set.');
		}
	}

	cancelSubscription() {
		return this.model.set('showconfirm', true);
	}


	usageGraph() {
		const brushed = function() {
			if (d3.event.sourceEvent && (d3.event.sourceEvent.type === 'zoom')) {
				return;
			}
			// ignore brush-by-zoom
			const s = d3.event.selection || x2.range();
			x.domain(s.map(x2.invert, x2));
			focus.select('.area').attr('d', area);
			focus.select('.axis--x').call(xAxis);
			svg.select('.zoom').call(zoom.transform, d3.zoomIdentity.scale(width / (s[1] - (s[0]))).translate(-s[0], 0));
		};

		const zoomed = function() {
			if (d3.event.sourceEvent && (d3.event.sourceEvent.type === 'brush')) {
				return;
			}
			// ignore zoom-by-brush
			const t = d3.event.transform;
			x.domain(t.rescaleX(x2).domain());
			focus.select('.area').attr('d', area);
			focus.select('.axis--x').call(xAxis);
			context.select('.brush').call(brush.move, x.range().map(t.invertX, t));
		};

		let parseDate = d3.timeParse("%Q");

		const data = (Array.from(this.model.root.get('_page.usagebyuser') || []).map((u) => ({ date: parseDate(u.ts), usage: u.usage })));

		var svg = d3.select('svg');

		const margin = { 
			top: 20,
			right: 20,
			bottom: 110,
			left: 40
		};

		const margin2 = { 
			top: 430,
			right: 20,
			bottom: 30,
			left: 40
		};

		var width = +svg.attr('width') - (margin.left) - (margin.right);
		const height = +svg.attr('height') - (margin.top) - (margin.bottom);
		const height2 = +svg.attr('height') - (margin2.top) - (margin2.bottom);
		parseDate = d3.timeParse('%b %Y');

		var x = d3.scaleTime().range([
			0,
			width
		]);

		var x2 = d3.scaleTime().range([
			0,
			width
		]);

		const y = d3.scaleLinear().range([
			height,
			0
		]);

		const y2 = d3.scaleLinear().range([
			height2,
			0
		]);

		var xAxis = d3.axisBottom(x);
		const xAxis2 = d3.axisBottom(x2);
		const yAxis = d3.axisLeft(y);
		var brush = d3.brushX().extent([
			[
				0,
				0
			],
			[
				width,
				height2
			]
		]).on('brush end', brushed);

		var zoom = d3.zoom().scaleExtent([
			1,
			Infinity
		]).translateExtent([
			[
				0,
				0
			],
			[
				width,
				height
			]
		]).extent([
			[
				0,
				0
			],
			[
				width,
				height
			]
		]).on('zoom', zoomed);

		var area = d3.area().curve(d3.curveMonotoneX).x(d => x(d.date)).y0(height).y1(d => y(d.usage));

		const area2 = d3.area().curve(d3.curveMonotoneX).x(d => x2(d.date)).y0(height2).y1(d => y2(d.usage));

		svg.append('defs').append('clipPath').attr('id', 'clip').append('rect').attr('width', width).attr('height', height);

		var focus = svg.append('g').attr('class', 'focus').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		var context = svg.append('g').attr('class', 'context').attr('transform', 'translate(' + margin2.left + ',' + margin2.top + ')');

		// set data

		x.domain(d3.extent(data, d => d.date)
		);

		y.domain([
			0,
			d3.max(data, d => d.usage)
		]);

		x2.domain(x.domain());
		y2.domain(y.domain());
		focus.append('path').datum(data).attr('class', 'area').attr('d', area);
		focus.append('g').attr('class', 'axis axis--x').attr('transform', 'translate(0,' + height + ')').call(xAxis);
		focus.append('g').attr('class', 'axis axis--y').call(yAxis);
		context.append('path').datum(data).attr('class', 'area').attr('d', area2);
		context.append('g').attr('class', 'axis axis--x').attr('transform', 'translate(0,' + height2 + ')').call(xAxis2);
		context.append('g').attr('class', 'brush').call(brush).call(brush.move, x.range());
		return svg.append('rect').attr('class', 'zoom').attr('width', width).attr('height', height).attr('transform', 'translate(' + margin.left + ',' + margin.top + ')').call(zoom);
	}
}

app.component('dashboard', Dashboard);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}