/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const getRandomInt = (min, max) => Math.floor(Math.random() * ((max - min) + 1)) + min;

const util = {

	toServer(user, method, data, cb) {
		if (data == null) { data = {}; }
		return user.setNull("methods", {}, err => user.del(`methods.${method}`, err => user.set(`methods.${method}`, data, function(err) {
            if (cb) { return cb(); }
        })));
	},

	redirect(app, page, url) {
		try {
			if (__guard__(app != null ? app.history : undefined, x => x.push)) {
				return app.history.push(url);
			} else if (__guard__(page != null ? page.res : undefined, x1 => x1.redirect)) {
				return page.res.redirect(url);
			}
		} catch (err) {
			return console.error('[ util.redirect ]', url, ':', err);
		}
	},

	randomKey(len) {
		const buf = [];
		const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
		const charlen = chars.length;
		let i = 0;

		while (i < len) {
			buf.push(chars[getRandomInt(0, charlen - 1)]);
			++i;
		}

		return buf.join('');
	}
};



module.exports = util;


function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}