/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');

const ignoreUrls = [ '/logout', '/logout/', '/profiili/email-change', '/profiili/email-change/', '/edit-profile/choose', '/edit-profile/choose/'];
const isLoggedInF = function() { return !!this.model.root.get('_session.loggedIn'); };

const redirect1 = function(app, page, url) {
  try {
    if (__guard__(app != null ? app.history : undefined, x => x.push)) {
      return app.history.push(url);
    } else if (__guard__(page != null ? page.res : undefined, x1 => x1.redirect)) {
      return page.res.redirect(url);
    }
  } catch (err) {
    return console.error('[ util.redirect ]', url, ':', err);
  }
};

const handleUserReg = function(page, model, params, next) {
  const logout = function(s) {
    console.log('force logout', userId, s);
    const redirect2 = function(server) {
      if (server) {
        return redirect1(app, page, '/logout', false);
      } else {
        // a small hack since the framework doesn't use windows.location bu
        // pushState and that won't work as we need to go to the server
        return window.location.href = '/logout';
      }
    };

    if (typeof window === 'undefined') {
      //model.flash 'error', s
      //model.toast 'error', s
      //setTimeout (-> redirect2(true)), 5000
      redirect2(true);
      // don't call next() because that will cause 'headers already sent' error
      // todo: find out why and fix this
      return;
    } else {
      model.toast('error', s);
      return setTimeout(redirect2, 5000);
    }
  };

  var userId = model.get('_session.userId');
  const isLoggedIn = isLoggedInF.call(this);
  //console.log 'user', userId, model.get '_session'

  if (!userId) {
    if (isLoggedIn) {
      logout('Tapahtui virhe - kokeile kirjautua uudelleen sisään. (1)');
    }
    return next();
  }

  const handleUserRegCb = err => {
    if (err) { return next(err); }
    const user = userQ.get();

    // if a user is set, USERS.isLoggedIn should return true
    // if it doesn't, we are in a middling state and we should log out
    if (user && !isLoggedIn) {
      return logout('Tapahtui virhe - kokeile kirjautua uudelleen sisään. (2)');
    } else if (!user && isLoggedIn) {
      return logout('Tapahtui virhe - kokeile kirjautua uudelleen sisään. (3)');
    } else if (user) {
      if (!isLoggedIn) {
        logout('Tapahtui virhe - kokeile kirjautua uudelleen sisään. (4)');
      } else if ((user.local != null ? user.local.status : undefined) === 'deleted') {
        logout('Sorry, this account has been disabled.');
      } else {
        model.root.ref('_page.user',  userQ);
        model.root.ref('_page.user_pvt',  userPvtQ);

        // handle 2fa/totp
        /*
        otp = userPvtQ.get 'local.otp'
        sessionOtp = model.root.get '_session.otp'

        console.log otp, sessionOtp, params.url
        if otp and sessionOtp isnt 'totp' and params.url.toLowerCase() isnt '/login-otp'
          redirect1 app, page, '/login-otp'
          return
        */
      }
 
      return next();
    } else {
      return next();
    }
  };

  var userQ = model.at(`auths.${userId}`, true);
  var userPvtQ = model.at(`auths_pvt.${userId}`, true);

  return model.subscribe(userQ, userPvtQ, handleUserRegCb);
};


// must be first
app.get('*', handleUserReg);
app.post('*', handleUserReg);

app.get('/login', (page, model, params, next) => page.render('login'));

app.get('/password-reset', (page, model, params, next) => page.render('login:password-reset'));

app.get('/register', (page, model, params, next) => page.render('register'));

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}