/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');

const COMBINED_MAX_LENGTH_FOR_SMALL_CARD = 180;

const trim = function(description, len) {
	if (description.length > len) {
		description = description.substring(0, len);
		description = description.substring(0, description.lastIndexOf(' ')) + '...';
	}

	description = description.trim();
	return description;
};

class Card {

	constructor() {
		this.keydown = this.keydown.bind(this);
	}

	create() {
		return window.iFrameResize({ checkOrigin: false });
	}

	html5Video() {
		const item = this.model.get('item');
		return ((item != null ? item.type : undefined) === 'video') && (__guard__(__guard__(__guard__(item != null ? item.videos : undefined, x2 => x2[0]), x1 => x1.type), x => x.substring(0, 5)) === 'video') && (__guard__(__guard__(item != null ? item.videos : undefined, x4 => x4[0]), x3 => x3.type) !== 'video/flash');
	}

	iframeVideo() {
		const item = this.model.get('item');
		return __guard__(item != null ? item.videos : undefined, x => x[0]) && ((item.videos[0].type === 'video/flash') || ((item.videos[0].type != null ? item.videos[0].type.substring(0, 5) : undefined) !== 'video'));
	}

	play() {
		this.model.set('play', true);
		return document.addEventListener('keydown', this.keydown, true);
	}
		
	stop() {
		return this.model.del('play');
	}

	descriptionForSmallCard(item) {

		if (item) {
			let description;
			const l = ((item.title != null ? item.title.length : undefined) + (item.description != null ? item.description.length : undefined)) - COMBINED_MAX_LENGTH_FOR_SMALL_CARD;
			if (l > 0) {
			  description = trim(item.description, item.description.length - l);
		}

			return description || item.description;
		} else {
			return '';
		}
	}

	publishedDate(date) {
		if (date != null ? date.parsed : undefined) {
			const d = Date.parse(date.parsed);
			if (d) {
				return new Date(d).toLocaleString();
			}
		}

		return '';
	}
		
	preferOembedInIframe() {
		return false;
	}

	preferOembedStraight() {
		if (this.model.get('small')) { return false; }

		const item = this.model.get('item');
		return (__guard__(item != null ? item.oembed : undefined, x => x.type) === 'rich') && (item != null ? item.url.match(/^https?:\/\/(?:www\.)?(?:airtable\.com|mathembed\.com|soundcloud\.com|facebook\.com|twitter\.com)/i) : undefined);
	}

	showArticle(ms) {
		clearTimeout(this.timer);
		const set = () => this.model.set('showArticle', true);
		return this.timer = setTimeout(set, ms);
	}
		
	hideArticle(ms) {
		clearTimeout(this.timer);
		const hide = () => this.model.del('showArticle');
		return this.timer = setTimeout(hide, ms);
	}
		
	videoSize() {
		const item = this.model.get('item');
		if (__guard__(item != null ? item.videos : undefined, x => x[0])) {
			const v = __guard__(item != null ? item.videos : undefined, x1 => x1[0]);
			const size = this.calculateOptimalDimensions(v.width, v.height, v.fixedHeight);
			return `width: ${size.w}; height: ${size.h}`;
		} else {
			return "";
		}
	}

	hasText() {
		const item = this.model.get('item');
		return (item != null ? item.title : undefined) && (item != null ? item.description : undefined);
	}

	keydown(e) {
		const key = e.keyCode || e.which;
		if (key === 27) {
			e.stopPropagation();
			document.removeEventListener('keydown', this.keydown, true);
			return this.stop();
		}
	}

	// expand (video) size to the maxwidth/maxheight
	// some sites want to have a fixed height video/swf, so take that into account
	calculateOptimalDimensions(width, height, isFixedHeightVideo) {
		let w = '';
		let h = '';

		if (width && height) {
			const ratio = width / height;
			w = '90vw';
			h = isFixedHeightVideo ? height(+'px') : `${90 / ratio}vw`;
		}
		return { w, h };
	}


	dimensions(image) {
		let h, w;
		const small = this.model.get('small');

		if (small) {
			h = 125;
			w = 125 * (image.width / image.height);
		} else {
			w = 750;
			h = (750 * image.height) / image.width;
		}

		return `width: ${w}px; height: ${h}px`;
	}


	showImage(image) {
		console.log('showImage', image);
		if (image.classList) {
			return setTimeout((() => image.classList.add('show')), 0);
		} else { 
			return setTimeout((() => image.className += ' show'), 10);
		}
	}
}
		

app.component('card', Card);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}