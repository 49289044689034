/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const util = require('../util');
const base32 = require('thirty-two');

app.get('/settings', (page, model, params, next) => page.render('settings'));


app.get('/settings/password', (page, model, params, next) => page.render('settings:settings-password'));


app.get('/settings/email', (page, model, params, next) => page.render('settings:settings-email'));


app.get('/settings/delete', (page, model, params, next) => page.render('settings:settings-delete'));


app.get('/settings/2fa-disable', (page, model, params, next) => page.render('settings:settings-2fa-disable'));

app.get('/settings/2fa-disabled', (page, model, params, next) => page.render('settings:settings-2fa-disabled'));

app.get('/settings/2fa-disable-failed', (page, model, params, next) => page.render('settings:settings-2fa-disable-failed'));


// this is in server/routes
//
//app.post '/settings/2fa-disable', (page, model, params, next) ->
//	util.toServer user, 'disable-otp', { password: params.password }
//	page.render 'settings:settings-2fa-disable'

app.get('/settings/2fa', function(page, model, params, next) {
	const keyExists = this.model.get('_page.user_pvt.local.otp');

	if (keyExists) {
		return page.render('settings:settings-2fa-exists');
	} else {
		return page.render('settings:settings-2fa-1');
	}
});


app.get('/settings/2fa-2', function(page, model, params, next) {
	const keyExists = this.model.get('_page.user_pvt.local.otp');
	const user = model.root.at('_page.user');

	if (keyExists) {
		return util.redirect(app, page, '/settings/2fa-exists');
	} else {
		const key = util.randomKey(10);
		const encodedKey = base32.encode(key);

		// generate QR code for scanning into Google Authenticator
		// // reference: https://code.google.com/p/google-authenticator/wiki/KeyUriFormat
		const email = this.model.get('_page.user_pvt.local.email');
		const otpUrl = `otpauth://totp/${email}?secret=${encodedKey}&period=30&issuer=Embed.rocks`;
		const qrImage = `https://chart.googleapis.com/chart?chs=166x166&chld=L|0&cht=qr&chl=${encodeURIComponent(otpUrl)}`;
		this.model.set('_page.otp_image', qrImage);
		util.toServer(user, 'enabling-otp', { key });

		return page.render('settings:settings-2fa-2');
	}
});

// this is moved to server/routes
/*
app.get '/settings/2fa-3', (page, model, params, next) ->
	user = model.root.at '_page.user'
	tmpkey = @model.get '_page.user_pvt.local.otp_tmp'

	* are we succesfully passed the test?
	* if so, let's enable the 2fa
	if model.get("_page.otp_test") is 'ok' && tmpkey
		util.toServer user, 'enable-otp', { }
		util.redirect app, page, '/settings/2fa-4'
		return

	page.render 'settings:settings-2fa-3'
*/

// this is moved to server/routes
/*

app.get '/settings/2fa-4', (page, model, params, next) ->
	page.render 'settings:settings-2fa-4'

*/

app.get('/settings/2fa-exists', (page, model, params, next) => page.render('settings:settings-2fa-exists'));


