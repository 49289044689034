/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');

app.get('/architecture', (page, model, params, next) => page.render('static:architecture'));

app.get('/tos', (page, model, params, next) => page.render('static:tos'));

app.get('/about', (page, model, params, next) => page.render('static:about'));

app.get('/privacy', (page, model, params, next) => page.render('static:privacy'));

