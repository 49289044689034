/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const app = require('../index');
const conf = require('../../conf/common');

class Prices {

	constructor() {
		this.calcNewPrice = this.calcNewPrice.bind(this);
	}

	create() {
		this.model.set('count', '');
		this.model.set('price', 0);
		this.model.on('change', 'count', this.calcNewPrice);
		return this.input.focus();
	}

	calcNewPrice() {
		const round = num => num.toFixed(2);
		const v = round(Math.max(0, ((this.model.get('count')) * conf.price)));
		return this.model.set('price', isNaN(v) ? '' : v);
	}
}

app.component('pricing', Prices);
